import React, { useState, useRef, useContext, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";

import axios from "axios";

import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

import CreateAccount from "./CreateAccount";

export default function Home() {
   return (
      <Box
         sx={{
            position: "relative",
            width: "100%",
            maxWidth: 550,
            mx: "auto",
            p: 4,
         }}
      >
         <Typography variant='h3' sx={{ textAlign: "center" }}>
            Story Recorder
         </Typography>

         <Box sx={{ p: 4 }}>
            <b>
               An fast and easy way to collect audio stories from family and
               friends
            </b>
            <p>
               Description text here description text here description text here
               description text here description text here description text
               here.
            </p>
         </Box>
         <CreateAccount />
      </Box>
   );
}

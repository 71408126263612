import React, { useRef, useState, useEffect, useCallback } from "react";

import { Button, Box, Stack, Chip } from "@mui/material";

import WaveSurfer from "wavesurfer.js";
import Timeline from "wavesurfer.js/plugins/timeline";

import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";

// WaveSurfer hook
const useWavesurfer = (containerRef, options) => {
   const [wavesurfer, setWavesurfer] = useState(null);

   // Initialize wavesurfer when the container mounts
   // or any of the props change
   useEffect(() => {
      if (!containerRef.current) return;

      const ws = WaveSurfer.create({
         ...options,
         container: containerRef.current,
      });

      setWavesurfer(ws);

      return () => {
         ws.destroy();
      };
   }, [options.url, options.timestamp, containerRef]);

   return wavesurfer;
};

// Create a React component that will render wavesurfer.
// Props are wavesurfer options.
const WaveSurferPlayer = (props) => {
   const containerRef = useRef();

   const [isPlaying, setIsPlaying] = useState(false);
   const [currentTime, setCurrentTime] = useState(0);
   const [totalTime, setTotalTime] = useState(null);

   const [currentClipLoop, setCurrentClipLoop] = useState(null);
   const [currentClipAutoplay, setCurrentClipAutoplay] = useState(null);

   const wavesurfer = useWavesurfer(containerRef, props);

   const formatDuration = (totalSeconds) => {
      const hours = parseInt(Math.floor(totalSeconds / 3600))
         .toString()
         .padStart(2, "0");
      const minutes = parseInt(Math.floor((totalSeconds % 3600) / 60))
         .toString()
         .padStart(2, "0");
      const seconds = parseInt(totalSeconds - hours * 3600 - minutes * 60)
         .toString()
         .padStart(2, "0");

      return [hours, minutes, seconds].filter((item) => item !== "0").join(":");
   };

   // On play button click
   const onPlayClick = useCallback(() => {
      wavesurfer.isPlaying() ? wavesurfer.pause() : wavesurfer.play();
   }, [wavesurfer]);

   useEffect(() => {
      if (!wavesurfer) return;

      setCurrentClipLoop(wavesurfer.options.loop);
      setCurrentClipAutoplay(wavesurfer.options.autoplay);
      setCurrentTime(0);
      setIsPlaying(false);

      const subscriptions = [
         wavesurfer.on("play", () => setIsPlaying(true)),
         wavesurfer.on("pause", () => setIsPlaying(false)),
         wavesurfer.on("ready", (duration) => {
            setTotalTime(duration);
         }),
         wavesurfer.on("timeupdate", (currentTime) => {
            setCurrentTime(currentTime);
         }),
         wavesurfer.on("finish", () => {
            if (props.loop) {
               wavesurfer.play();
            }
         }),
      ];

      return () => {
         subscriptions.forEach((unsub) => unsub());
      };
   }, [wavesurfer]);

   return (
      <Stack spacing={2} direction={props.direction} alignItems='center'>
         <Box
            ref={containerRef}
            sx={{
               height: props.height,
               width: props.direction === "row" ? "50%" : "100%",
            }}
         />

         <Box>
            {formatDuration(currentTime)} / {formatDuration(totalTime)}
         </Box>

         <Button
            onClick={onPlayClick}
            sx={{ mt: 2 }}
            variant={isPlaying ? "contained" : "outlined"}
            color={isPlaying ? "success" : "primary"}
            startIcon={isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
         >
            {isPlaying ? "Pause" : "Play"}
         </Button>
      </Stack>
   );
};

export default function WaveformPlayer({
   height = 100,
   direction = "column",
   autoplay,
   audioUrl,
   loop,
   timestamp,
   waveColor,
   progressColor,
   cursorColor,
}) {
   return (
      <>
         {audioUrl && (
            <WaveSurferPlayer
               height={height}
               direction={direction}
               waveColor={waveColor}
               progressColor={progressColor}
               cursorColor={cursorColor}
               barWidth='3'
               barGap='3'
               url={audioUrl}
               // plugins={[Timeline.create()]}
               autoplay={autoplay}
               loop={loop}
            />
         )}
      </>
   );
}

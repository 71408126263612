import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { motion, AnimatePresence } from "framer-motion";
import axios from "axios";

import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function WrapperExists({ children }) {
   let { uuid } = useParams();

   const [exists, setExists] = useState(null);

   useEffect(() => {
      if (uuid) {
         axios
            .post(SERVER_URL + "/checkstory", {
               id: uuid,
            })
            .then(function (response) {
               setExists(response.data);
            });
      } else {
         setExists(false);
      }
   }, []);

   return (
      <>
         <AnimatePresence>
            {exists === true && (
               <Box
                  component={motion.div}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
               >
                  {children}
               </Box>
            )}
         </AnimatePresence>
         {exists === false && (
            <AnimatePresence>
               <Box
                  component={motion.div}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  className='pageBG'
                  sx={{
                     p: 4,
                     boxSizing: "border-box",
                  }}
               >
                  {/* HEADER */}
                  <Stack direction='row' spacing={4} alignItems='center'>
                     <Typography
                        variant='h2'
                        sx={{ whiteSpace: "nowrap", width: "100%" }}
                     >
                        Story Doesn't Exist! Please check the Story Submission
                        URL
                     </Typography>
                  </Stack>
               </Box>
            </AnimatePresence>
         )}
      </>
   );
}

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { motion } from "framer-motion";

import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import { saveAs } from "file-saver";
import axios from "axios";
import _ from "lodash";

import {
   Box,
   Grid,
   Button,
   Stack,
   Typography,
   TextField,
   IconButton,
   ToggleButton,
   ToggleButtonGroup,
} from "@mui/material";

import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/DeleteForever";
import SendIcon from "@mui/icons-material/Send";

import WaveformPlayer from "./WaveformPlayer";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function Recorder() {
   let { uuid } = useParams();

   const [contact, setContact] = useState(null);
   const [email, setEmail] = useState(null);
   const [phone, setPhone] = useState(null);

   const [loaded, setLoaded] = useState(null);
   const [recordings, setRecordings] = useState([]);

   const [phoneEmail, setPhoneEmail] = React.useState("email");

   const handleChange = (e, newValue) => {
      setPhoneEmail(newValue);
   };

   const recorderControls = useAudioRecorder();

   async function blobToDataURL(blobURL, callback) {
      return fetch(blobURL)
         .then((res) => res.blob())
         .then((blob) => {
            return blob;
         });
   }

   useEffect(() => {
      if (loaded) {
      }
   }, [loaded]);

   useEffect(() => {
      if (recorderControls.isRecording) {
         setLoaded(null);
      }
   }, [recorderControls.isRecording]);

   const addAudioElement = (blob) => {
      const audioUrl = URL.createObjectURL(blob);

      setRecordings((prevState) => {
         return [
            ...prevState,
            {
               audio: audioUrl,
               title: "Recorded " + new Date().toLocaleString("en-us"),
               timestamp: Date.now(),
               saved: false,
            },
         ];
      });
   };

   const deleteAudio = (i) => {
      setRecordings((prevState) => {
         let newState = [...prevState];
         newState.splice(i, 1);
         return newState;
      });
   };

   const saveAudio = async () => {
      if (uuid) {
         const fileName = loaded.timestamp + "_" + uuid + ".mp3";
         const file = new File([await blobToDataURL(loaded.audio)], fileName);

         // SAVE FILE
         // saveAs(loaded.audio, fileName);

         // UPLOAD
         var formData = new FormData();
         formData.append("fileName", fileName);
         formData.append("uuid", uuid);
         formData.append("file", file);
         formData.append("email", email ? email : "");
         formData.append("phone", phone ? phone : "");

         fetch(SERVER_URL + "/save", {
            method: "POST",
            body: formData,
         }).then((response) => {
            alert("Story has been saved to the server");

            setLoaded(null);

            setRecordings((prevState) => {
               let newState = [...prevState];
               const index = _.findIndex(newState, {
                  timestamp: loaded.timestamp,
               });
               newState[index].saved = true;
               return newState;
            });
         });
      } else {
         alert("No UUID!");
      }
   };

   const saveContact = () => {
      console.log("save");
   };

   return (
      <Box sx={{ p: 4, mx: "auto", maxWidth: 1200 }}>
         {!contact && (
            <Stack
               alignItems='center'
               spacing={2}
               sx={{ mx: "auto", maxWidth: 400 }}
            >
               <Typography variant='h4' sx={{ fontWeight: "bold" }}>
                  Identify Yourself
               </Typography>

               <Typography variant='p'>
                  Please provide either an email or phone number so the Story
                  administrator knows who is sending this story.
               </Typography>

               <ToggleButtonGroup
                  color='primary'
                  value={phoneEmail}
                  exclusive
                  onChange={handleChange}
               >
                  <ToggleButton value='email'>Email</ToggleButton>
                  <ToggleButton value='phone'>Phone</ToggleButton>
               </ToggleButtonGroup>

               {phoneEmail === "email" && (
                  <TextField
                     fullWidth
                     placeholder='Email'
                     inputProps={{
                        autoFocus: true,
                     }}
                     name='email'
                     onChange={(e) => setEmail(e.target.value)}
                  />
               )}

               {phoneEmail === "phone" && (
                  <TextField
                     fullWidth
                     placeholder='Phone'
                     type='phone'
                     name='phone'
                     onChange={(e) => setPhone(e.target.value)}
                  />
               )}

               <Button
                  variant='primary'
                  onClick={() => setContact(true)}
                  variant={email || phone ? "outlined" : "disabled"}
               >
                  Save
               </Button>
            </Stack>
         )}

         {contact && (
            <Grid
               container
               rowSpacing={4}
               columnSpacing={6}
               alignItems='stretch'
               justifyContent='center'
            >
               <Grid item xs={12} md={6}>
                  <Typography variant='h5' sx={{ px: 2 }}>
                     Record Your Story
                  </Typography>
                  <Typography variant='p' sx={{ fontStyle: "italic", px: 2 }}>
                     {email}
                     {email && phone && " | "}
                     {phone}
                  </Typography>
                  <ol>
                     <li>Make a plan for what you want to record</li>
                     <li>Click START RECORDING button</li>
                     <li>Stop the recording</li>
                     <li>Review your story audio recording</li>
                     <li>
                        Click <b>SAVE STORY</b> to deliver your story to friends
                        and family
                     </li>
                  </ol>
               </Grid>
               <Grid item xs={12} md={6}>
                  <Stack
                     spacing={2}
                     alignItems='center'
                     justifyContent='center'
                     sx={{
                        mb: 4,
                        p: 4,
                        borderRadius: 2,
                        color: "#fff",
                        width: "100%",
                        height: "100%",
                        backgroundColor:
                           recorderControls.isRecording === true
                              ? recorderControls.isPaused === true
                                 ? "#ccc"
                                 : "#f00"
                              : "transparent",
                     }}
                  >
                     <Typography variant='h6'>
                        {recorderControls.isPaused === false &&
                           recorderControls.isRecording === true && (
                              <>RECORDING</>
                           )}
                        {recorderControls.isPaused === true &&
                           recorderControls.isRecording === true && <>PAUSED</>}
                     </Typography>

                     <Stack direction='row' spacing={2}>
                        {recorderControls.isRecording === false && (
                           <Button
                              color='error'
                              size='large'
                              variant='contained'
                              onClick={() => recorderControls.startRecording()}
                           >
                              Start Recording
                           </Button>
                        )}

                        <AudioRecorder
                           onRecordingComplete={(blob) => addAudioElement(blob)}
                           recorderControls={recorderControls}
                           showVisualizer
                        />
                     </Stack>
                  </Stack>
               </Grid>

               <Grid item xs={12} md={6}>
                  {recordings.length > 0 && (
                     <Stack spacing={2} sx={{ width: "100%" }}>
                        <Typography variant='h6'>Recorded Memories</Typography>
                        <Stack spacing={0}>
                           {recordings.map((clip, i) => (
                              <Stack
                                 direction='row'
                                 spacing={2}
                                 alignItems='stretch'
                                 justifyContent='center'
                                 sx={{
                                    position: "relative",
                                    borderRadius: 1,
                                    borderTop: "1px solid #efefef",
                                    p: 2,
                                    backgroundColor:
                                       loaded && loaded.index === i
                                          ? "rgba(230,240,240,1)"
                                          : "transparent",
                                 }}
                              >
                                 <Box
                                    sx={{
                                       backgroundColor:
                                          loaded && loaded.index === i
                                             ? "rgba(230,240,240,1)"
                                             : "transparent",
                                       borderRadius: 1,
                                       position: "absolute",
                                       padding: 4,
                                       transform:
                                          "rotate(-45deg) translateX(50%) translateY(-50%)",
                                       transformOrigin: "top right",
                                       right: 10,
                                       top: "50%",
                                       height: 20,
                                       width: 20,
                                       display: { xs: "none", md: "block" },
                                    }}
                                 />

                                 <Stack
                                    sx={{
                                       backgroundColor: "#666",
                                       color: "#fff",
                                       px: 2,
                                       py: 1,
                                       borderRadius: 1,
                                       fontWeight: "bold",
                                    }}
                                    justifyContent='center'
                                 >
                                    {i + 1}
                                 </Stack>

                                 <Grid
                                    container
                                    rowSpacing={1}
                                    columnSpacing={2}
                                    justifyContent='center'
                                 >
                                    <Grid
                                       item
                                       xs={12}
                                       md={12}
                                       sx={{ textAlign: "center" }}
                                    >
                                       <Stack
                                          direction='row'
                                          alignItems='center'
                                          spacing={2}
                                          sx={{ mb: 1 }}
                                       >
                                          <Button
                                             fullWidth
                                             variant='outlined'
                                             color='success'
                                             sx={{ width: "50%" }}
                                             onClick={() =>
                                                setLoaded({ ...clip, index: i })
                                             }
                                          >
                                             Review
                                          </Button>

                                          <Box sx={{ width: "50%" }}>
                                             {!clip.saved && (
                                                <Box sx={{ color: "#f00" }}>
                                                   Not saved <br />
                                                   Please review
                                                </Box>
                                             )}

                                             {clip.saved && (
                                                <Button
                                                   startIcon={<CheckIcon />}
                                                   variant='disabled'
                                                >
                                                   SAVED
                                                </Button>
                                             )}
                                          </Box>
                                       </Stack>

                                       <i
                                          style={{
                                             textAlign: "center",
                                             color: "#ccc",
                                          }}
                                       >
                                          {clip.title}
                                       </i>
                                    </Grid>
                                 </Grid>
                              </Stack>
                           ))}
                        </Stack>
                     </Stack>
                  )}
               </Grid>

               <Grid item xs={12} md={6}>
                  {recordings.length > 0 && (
                     <>
                        {!loaded && (
                           <Stack spacing={2} sx={{ width: "100%" }}>
                              <Typography variant='h6'>Review</Typography>

                              <Stack
                                 justifyContent='center'
                                 sx={{
                                    backgroundColor: "#ccc",
                                    borderRadius: 2,
                                    p: 4,
                                    color: "rgba(255,255,255,.8)",
                                    textAlign: "center",
                                    minHeight: 400,
                                 }}
                              >
                                 <Box>Please Select a Story to Review</Box>
                              </Stack>
                           </Stack>
                        )}

                        {loaded && (
                           <Stack spacing={2} sx={{ width: "100%" }}>
                              <Typography variant='h6'>Review</Typography>

                              <Box
                                 sx={{
                                    backgroundColor: "#333",
                                    borderRadius: 2,
                                    p: 4,
                                    color: "#fff",
                                 }}
                              >
                                 <WaveformPlayer
                                    audioUrl={loaded.audio}
                                    autoplay={true}
                                    waveColor={"rgba(200, 0, 0,1)"}
                                    progressColor={"rgba(255,255,255,.4)"}
                                    cursorColor={"#fff"}
                                 />

                                 {!loaded.saved && (
                                    <Box
                                       as={motion.div}
                                       initial={{ scale: 1 }}
                                       animate={{ scale: 1.1 }}
                                       transition={{
                                          duration: 1,
                                          repeat: Infinity,
                                          repeatType: "mirror",
                                       }}
                                       sx={{
                                          width: "100%",
                                          textAlign: "center",
                                          my: 3,
                                       }}
                                    >
                                       <Button
                                          variant='contained'
                                          color='error'
                                          size='large'
                                          onClick={saveAudio}
                                          startIcon={<SendIcon />}
                                       >
                                          SAVE STORY
                                       </Button>
                                    </Box>
                                 )}

                                 <Box sx={{ p: 2, textAlign: "center" }}>
                                    <i>{loaded.title}</i>
                                 </Box>

                                 {loaded.saved && (
                                    <Button
                                       fullWidth
                                       startIcon={<CheckIcon />}
                                       variant='disabled'
                                       sx={{ color: "#ccc !important" }}
                                    >
                                       SAVED
                                    </Button>
                                 )}
                              </Box>
                           </Stack>
                        )}
                     </>
                  )}
               </Grid>
            </Grid>
         )}
      </Box>
   );
}

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import { saveAs } from "file-saver";
import axios from "axios";

import {
   Box,
   Grid,
   Button,
   Stack,
   Typography,
   TextField,
   IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/DeleteForever";
import SendIcon from "@mui/icons-material/Send";

import WaveformPlayer from "./WaveformPlayer";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function Admin() {
   let { uuid } = useParams();
   const verification = localStorage.getItem("verification");

   const [stories, setStories] = useState([]);

   const getStories = () => {
      if (verification) {
         axios
            .post(SERVER_URL + "/getstories", {
               id: uuid,
               code: verification,
            })
            .then(function (response) {
               setStories(response.data.Stories);
            });
      }
   };

   useEffect(() => {
      getStories();
   }, []);

   useEffect(() => {
      console.log(stories);
   }, [stories]);

   return (
      <Box sx={{ p: 4, mx: "auto", maxWidth: 1200 }}>
         <Box>
            <Typography variant='h5'>
               Welcome to your Story Recorder Dashboard
            </Typography>

            <p>
               Congratulations on setting up your Story Recorder account! To
               collect audio stories from friends and family:
            </p>

            <ol>
               <li>
                  Share the Public Story Sharing Link (shown below) with friends
                  and family
               </li>
               <li>
                  Every time someone makes a recording you will be notified via
                  email!
               </li>
               <li>Other info...</li>
            </ol>

            <p>
               <b>Public Story Sharing Link:</b>
               <br />
               <TextField
                  sx={{ width: 700 }}
                  value={SERVER_URL + "/story/" + uuid}
                  onFocus={(event) => {
                     setTimeout(function () {
                        event.target.select();
                     }, 100);
                  }}
               />
            </p>
         </Box>

         {stories.length > 0 && (
            <Typography variant='h4' sx={{ mt: 4, mb: 1 }}>
               Submissions
            </Typography>
         )}

         {stories.length == 0 && (
            <Typography variant='h6' sx={{ mt: 4, mb: 1, color: "#ccc" }}>
               No stories submitted yet...
            </Typography>
         )}

         <Stack spacing={2}>
            {stories.map((story) => (
               <Stack
                  direction='row'
                  spacing={4}
                  sx={{ borderTop: "1px solid #ccc", py: 2 }}
                  alignItems='center'
               >
                  <Box sx={{ width: "70%" }}>
                     <WaveformPlayer
                        height={50}
                        direction='row'
                        audioUrl={SERVER_URL + "/uploads/" + story.fileName}
                        autoplay={false}
                        waveColor={"rgba(200, 0, 0,1)"}
                        progressColor={"rgba(200,0,0,.2)"}
                        cursorColor={"#f00"}
                     />
                  </Box>
                  <Box sx={{ width: "30%" }}>
                     <Stack>
                        <b>
                           {new Date(story.createdAt).toLocaleString("en-US")}
                        </b>
                        <Box>{story.email}</Box>
                        <Box>{story.phone}</Box>
                     </Stack>
                  </Box>
               </Stack>
            ))}
         </Stack>
      </Box>
   );
}

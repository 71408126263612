import React, { useState, useRef, useContext, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";

import axios from "axios";

import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

// VALIDATION
import { useFormik } from "formik";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function CreateAccount() {
   const [created, setCreated] = useState(false);

   // FUNCTIONS
   const register = (email) => {
      if (email) {
         axios
            .post(SERVER_URL + "/register", {
               email: email,
            })
            .then(function (response) {
               if (response) {
                  setCreated(response.data);
               }
            });
      }
   };

   const validate = (values) => {
      const errors = {};

      if (!values.email) {
         errors.email = "Required";
      } else if (
         !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
      ) {
         errors.email = "Invalid email address";
      }

      return errors;
   };

   const formik = useFormik({
      initialValues: {
         email: "",
      },
      validate,
      onSubmit: (values) => {
         register(values.email);
      },
   });

   return (
      <Box
         sx={{
            position: "relative",
            width: "100%",
            minHeight: 50,
            maxWidth: 500,
            mx: "auto",
            border: "4px solid #ccc",
            borderRadius: 2,
         }}
      >
         <AnimatePresence>
            {!created && (
               <Box sx={{ m: 4 }}>
                  <Typography variant='subtitle1' sx={{ color: "#333", pb: 2 }}>
                     Please enter email address to create a Story account:
                  </Typography>

                  <form onSubmit={formik.handleSubmit}>
                     <Stack>
                        <TextField
                           type='email'
                           label='Email Address'
                           name='email'
                           id='email'
                           onChange={formik.handleChange}
                           onBlur={formik.handleBlur}
                           value={formik.values.email}
                        />

                        {formik.touched.email && formik.errors.email && (
                           <Typography variant='subtitle2'>
                              {formik.errors.email}
                           </Typography>
                        )}

                        <Box
                           sx={{
                              fontSize: ".75rem",
                              textAlign: "left",
                              color: "rgba(0,0,0,.5)",
                              mt: 2,
                           }}
                        >
                           (We will not spam you or sign you up for a newsletter
                           – this is how you activate your Story account.)
                        </Box>

                        <Button
                           name='submit'
                           type='submit'
                           variant='contained'
                           color='secondary'
                           disabled={
                              formik.errors.email
                                 ? true
                                 : !formik.values.email
                                 ? true
                                 : false
                           }
                           sx={{ mt: 2 }}
                        >
                           Submit
                        </Button>
                     </Stack>
                  </form>
               </Box>
            )}
         </AnimatePresence>

         <AnimatePresence>
            {created && (
               <Stack
                  spacing={2}
                  component={motion.div}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  sx={{ p: 4 }}
               >
                  <Typography variant='h6' color='secondary' align='center'>
                     Congratulations, your Story Recorder account has been
                     created. Please check your email account for the login
                     information.
                  </Typography>
               </Stack>
            )}
         </AnimatePresence>
      </Box>
   );
}

import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from "./Home";
import Admin from "./Admin";
import Recorder from "./Recorder";

import Auth from "./WrapperAuth";
import Exists from "./WrapperExists";

import "./index.css";

// THEME
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

let theme = createTheme({
   palette: {
      type: "dark",
      text: {
         white: "#fff",
         primary: "#111",
         light: "#b1b1b1",
         medium: "#e0e0e0",
         mediumdark: "#1f1e1e",
         dark: "#7e7e7b",
         orange: "#ff6000",
      },
      alert: {
         main: "#e83411",
      },
      secondary: {
         main: "#555",
      },
      background: {
         light: "#e6e6e6",
         dark: "#111",
         gradient:
            "linear-gradient(90deg, rgba(38,37,37,1) 0%, rgba(88,87,88,1) 100%)",
         gradient5050:
            "linear-gradient(90deg, rgba(230,230,230,1) 0%, rgba(230,230,230,1) 50%, rgba(222,222,222,1) 50%, rgba(222,222,222,1) 100%)",
         gradientDark:
            "linear-gradient(90deg, rgba(29,29,29,1) 0%, rgba(31,31,31,1) 100%)",
      },
   },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
   <ThemeProvider theme={theme}>
      <CssBaseline />

      <Router>
         <Routes>
            <Route path='/' element={<Home />} />
            <Route
               path='/admin/:uuid'
               element={
                  <Auth>
                     <Admin />
                  </Auth>
               }
            />
            <Route
               path='/story/:uuid'
               element={
                  <Exists>
                     <Recorder />
                  </Exists>
               }
            />
         </Routes>
      </Router>
   </ThemeProvider>
);

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { motion, AnimatePresence } from "framer-motion";
import axios from "axios";

import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function WrapperAuth({ children }) {
   let { uuid } = useParams();

   const [email, setEmail] = useState("");
   const [password, setPassword] = useState("");
   const [loggedIn, setLoggedIn] = useState(null);
   const [error, setError] = useState("");

   const handleEmailUpdate = (e) => {
      const value = e.target.value;
      setEmail(value);

      if (e.key === "Enter") {
         e.target.blur();
         // SUBMIT PASSWORD
      }
   };

   const handlePasswordUpdate = (e) => {
      const value = e.target.value;
      setPassword(value);

      if (e.key === "Enter") {
         e.target.blur();
         // SUBMIT PASSWORD
      }
   };

   const login = () => {
      axios
         .post(SERVER_URL + "/checklogin", {
            id: uuid,
            email: email,
            password: password,
         })
         .then(function (response) {
            if (response.data.verified) {
               setLoggedIn(true);
               localStorage.setItem("verification", response.data.verification);
            } else {
               setError(
                  "Sorry, please check your Story Admin URL, email, and password, then try again. "
               );
            }
         });
   };

   useEffect(() => {
      const verification = localStorage.getItem("verification");

      if (verification) {
         axios
            .post(SERVER_URL + "/checkverification", {
               id: uuid,
               code: verification,
            })
            .then(function (response) {
               if (response.data.verified) {
                  setLoggedIn(true);
               } else {
                  setLoggedIn(false);
               }
            });
      } else {
         setLoggedIn(false);
      }
   }, []);

   return (
      <>
         <AnimatePresence>
            {loggedIn === true && (
               <Box
                  component={motion.div}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
               >
                  {children}
               </Box>
            )}
         </AnimatePresence>
         {loggedIn === false && (
            <AnimatePresence>
               <Box
                  component={motion.div}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  className='pageBG'
                  sx={{
                     p: 4,
                     boxSizing: "border-box",
                  }}
               >
                  {/* HEADER */}
                  <Stack direction='row' spacing={4} alignItems='center'>
                     <Typography
                        variant='h4'
                        sx={{
                           whiteSpace: "nowrap",
                           width: "100%",
                           textAlign: "center",
                        }}
                     >
                        Story Recorder Dashboard
                     </Typography>
                  </Stack>

                  <Stack
                     alignItems='center'
                     sx={{ mt: 6 }}
                     component={motion.div}
                     initial={{ opacity: 0 }}
                     animate={{ opacity: 1 }}
                     transition={{ delay: 0.3 }}
                  >
                     <Stack alignItems='center' spacing={2} sx={{ width: 400 }}>
                        <TextField
                           fullWidth
                           placeholder='Email'
                           inputProps={{
                              autoFocus: true,
                           }}
                           name='email'
                           onChange={handleEmailUpdate}
                        />

                        <TextField
                           fullWidth
                           placeholder='Password'
                           inputProps={{
                              autoFocus: true,
                           }}
                           type='password'
                           name='password'
                           onChange={handlePasswordUpdate}
                        />
                        <Button variant='outlined' onClick={login}>
                           Login
                        </Button>
                     </Stack>

                     {error && (
                        <Typography
                           variant='h4'
                           color='secondary'
                           sx={{ mt: 4 }}
                           component={motion.div}
                           initial={{ opacity: 0 }}
                           animate={{ opacity: 1 }}
                        >
                           {error}
                        </Typography>
                     )}
                  </Stack>
               </Box>
            </AnimatePresence>
         )}
      </>
   );
}
